p,
a,
li {
  font-size: 1.25rem;
  line-height: 1.75;
}

h1 {
  font-size: 2.5625rem;
  line-height: 1.2;
}

h2 {
  font-size: 1.75rem;
  line-height: 1.3;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.75;
}

small {
  font-size: 1rem;
}

figure.figure {
  padding: 2rem 0 2rem 0;
  margin: 0;
  background-color: white;
}

.figure-block {
  padding: $padLarge $padSmall;
}

.figure-block blockquote {
  // for homepage quote
  font-size: 1.25rem;
  line-height: 1.8;
  padding-left: 1em;
  border-left: 4px solid $color-brand;
}

.figure-block figcaption {
  padding-left: 1rem;
  border-left: 4px solid $color-brand;
  padding-top: 0.75em;
}

blockquote {
  // for content quotes
  padding: $padMedium $padSmall;
  p {
    padding-left: 0.5em;
    padding-top: 1em;
    margin: 0;
    border-left: 4px solid $color-brand;
  }
  p:first-of-type {
    padding-top: 0;
  }
}

.button {
  font-size: 1.25rem;
}

.button-small {
  padding: 0.5em 1.5em;
}

.button-header {
  font-size: 1rem;
}

.page-container {
  padding: 0 $padSmall;
}

.global-header {
  .header-container {
    padding: 15px 30px 0px 30px;
    display: grid;
    grid-template-areas: "logo buttons" "logo nav";

    .logo-container {
      grid-area: logo;
      width: auto;
      align-items: flex-start;
      padding-left: 0px;
    }

    .button-container {
      grid-area: buttons;
      width: auto;
      padding-right: 0px;
    }
  }

  .global-header__logo {
    max-width: 200px;
  }

  .global-header__top-container {
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: flex-start;
  }
}

.global-nav {
  padding: 0.1875em 0 1em 0;
  grid-area: nav;

  ul {
    justify-content: flex-end;
  }

  li a {
    font-size: 1.25rem;
  }

  li a.active {
    &:after {
      bottom: -0.9em;
    }
  }
}

.hero-container {
  -ms-grid-columns: 1fr 0.75fr;
  grid-template-columns: 1fr 0.75fr;
}

.figure-block {
  margin-top: $padSmall;
  margin-bottom: $padLarger;
}

.figure-block blockquote {
  // for homepage quote
  font-size: 1.375rem;
  line-height: 1.65;
}

main article blockquote {
  // for content quotes // article page content, pulled from CMS
  margin: $padLarge 0;
}

.who {
  .numbers {
    span {
      font-size: 3rem;
    }
  }
}

.who--homepage {
  background-image: url(../../assets/home-who-graphic.svg);
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: top right;

  .numbers-container {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .home-card-content {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    li a {
      height: 4em;
    }
  }
}

.cardgrid {
  gap: 1.5rem;
}

#learn {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 3;
  grid-column-end: 5;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 0;
  grid-row-end: 6;
  padding: 1.5rem 0;

  #learngrid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 3fr 1.5em 3fr;
    grid-template-columns: 3fr 3fr;
    grid-gap: 1.5em;
  }
}

.foot-one {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 0;
  grid-row-end: 2;
}
.foot-two {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 2;
  grid-column-end: 6;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 0;
  grid-row-end: 2;
}

.foot-three {
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-column-span: 2;
  grid-column-end: 8;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 0;
  grid-row-end: 2;

  ul {
    display: block;
  }

  ul li {
    text-align: left;
  }
}

.sidebar {
  padding-right: $padSmall;
}

.flex-left {
  flex-basis: 33%;
}
.flex-right {
  flex-basis: 67%;
}

.banner {
  display: flex;
  grid-column: 2 / span 4;
  grid-row: 1;
}

.card-link-container {
  a:first-of-type {
    font-size: 1.75rem;
    line-height: 1.3;
  }
}

footer {
  .footer__column {
    .home-card-content {
      li {
        a {
          height: 2.5em;
        }
      }
    }
  }

  .footer__bottom {
    padding-top: $padLarge;
    margin-top: $padMedium;
  }

  .footnotegrid {
    display: flex;
    justify-content: space-between;

    ul {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
      column-gap: 30px;

      li:nth-child(2) {
        -ms-grid-column: 2;
      }

      li:nth-child(3) {
        -ms-grid-column: 3;
      }

      li:nth-child(4) {
        -ms-grid-column: 4;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  footer .footnotegrid ul {
    margin-left: -30px;

    li {
      margin-left: 30px;
    }
  }
}

.format.list-items-boxed {
  ul {
    width: calc(100vw - 2 * #{$padSmall});
  }
}
